<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-container grid-list-md text-xs-center fluid>
            <v-layout wrap>
                <v-flex xs12>
                    <v-switch v-model="item.enable" :label="$t('Habilitado')" class="my-0 py-0" />
                </v-flex>
                <v-flex xs12>
                    <v-text-field :label="$t('Nome')" v-model="item.name" :rules="[rules.required, rules.min(5)]" class="my-0 py-0" />
                </v-flex>

                <v-flex xs12 sm6>
                    <v-text-field v-model="item.cpf" label="CPF" v-mask="'###.###.###-##'" return-masked-value />
                </v-flex>

                <v-flex xs12 sm6>
                    <v-text-field v-model="item.rg" label="RG" />
                </v-flex>

                <v-flex xs12 sm6>
                    <v-autocomplete v-model="item.gender" :items="genders" :label="$t('Sexo')" :rules="[rules.required]" />
                </v-flex>

                <v-flex xs12 sm6>
                    <v-text-field v-model="birthDate" :rules="[rules.required]" :label="$t('Data de nascimento')" inputmode="numeric" v-mask="'##/##/####'" />
                </v-flex>

                <v-flex xs12>
                    <v-text-field :label="$t('Nome da mãe')" v-model="item.motherName" :rules="[rules.required, rules.min(5)]" class="my-0 py-0" />
                    <v-text-field :label="$t('Nome do pai')" v-model="item.fatherName" :rules="[rules.min(5)]" class="my-0 py-0" />
                </v-flex>

                <v-flex xs12>
                    <v-text-field type="email" :label="$t('Email')" v-model="item.email" :rules="[rules.email]" />
                </v-flex>

                <v-flex xs12 md6>
                    <v-text-field
                        v-model="item.cellphone"
                        :rules="[rules.cellphone]"
                        label="Celular"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        return-masked-value
                        class="my-0 py-0"
                    />
                    <v-text-field
                        v-model="item.phone"
                        :rules="[rules.cellphone]"
                        label="Telefone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        return-masked-value
                        class="my-0 py-0"
                    />
                    <v-text-field :label="$t('Procedencia')" v-model="item.origin" class="my-0 py-0" />
                </v-flex>

                <v-flex xs12 md6>
                    <v-textarea :label="$t('Observações')" v-model="item.observation" class="my-0 py-0" />
                </v-flex>

                <v-flex xs12>
                    <Address :item="item" />
                </v-flex>

                <v-flex xs12 md12 sm12>
                    <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate">Salvar</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";
import Address from "@/components/form/Address";
import genders from "@/assets/json/genders.json";

export default {
    directives: { mask },
    components: { Address },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            genders,
            itemClean: {
                name: "",
                email: null,
                cpf: null,
                rg: null,
                birthDate: null,
                motherName: null,
                fatherName: null,
                cepOk: true,
                phone: null,
                cellphone: null,
                cep: "",
                state: "",
                city: "",
                district: "",
                address: "",
                houseNumber: "",
                complement: "",
                observation: null,
                origin: null,
                enable: true,
            },
            item: { ...this.itemClean },
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = { ...this.itemClean };
                    } else {
                        this.item = { ...newVal };
                    }
                });
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },

    computed: {
        birthDate: {
            get: function () {
                return this.item.birthDate ? this.$moment(this.item.birthDate).format("DD/MM/YYYY") : "";
            },
            set: function (data) {
                if (data && data.length == 10) {
                    this.item.birthDate = this.$moment(data, "DD/MM/YYYY");
                }
            },
        },
    },

    mounted() {
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>